<template>
	<section
		:class="{ dark }"
		class="teaser"
	>
		<component
			:is="wrapper"
			:class="[wrap && 'wrap', content.horizontal && 'horizontal', reverse && 'reverse']"
			customType="div"
			v-bind="wrap && link"
		>
			<LinkWrap
				v-if="image"
				:customType="'div'"
				class="image-container"
				v-bind="!wrap && !anchorLink && link"
			>
				<ImageResponsive
					auto-aspect
					v-bind="image"
				/>
			</LinkWrap>

			<div class="teaser-content-container">
				<TnHeading
					v-if="content.heading"
					v-editable-string="'content.heading'"
					:dark="dark"
					responsive
					size="m"
					type="h3"
					>{{ content.heading }}
				</TnHeading>

				<RichText
					v-if="content.text"
					:dark="dark"
					:text="content.text"
					class="padding-top-m teaser-text"
				/>

				<AnchorLink
					v-if="anchorLink"
					:button="content.showButton"
					chevronAnimated
					chevronRight
					class="margin-top-m"
					size="s"
					v-bind="anchorLink"
				/>
				<TnButton
					v-else-if="!wrap && content.showButton && link"
					:dark="dark"
					:data-comp-cat="bedriftCategory"
					:data-comp-lead="bedriftLead"
					:size="$store.getters.isMobile ? 's' : 'l'"
					class="margin-top-m"
					v-bind="link"
				/>
				<LinkInline
					v-else-if="!wrap && link"
					:dark="dark"
					:data-comp-cat="bedriftCategory"
					:data-comp-lead="bedriftLead"
					:size="$store.getters.isMobile ? 's' : 'l'"
					chevronRight
					class="margin-top-m"
					v-bind="link"
				/>
				<RichText
					v-if="bottomText"
					:dark="dark"
					:text="bottomText"
					class="padding-top-m"
				/>
			</div>
		</component>
	</section>
</template>

<script>
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";
import ResponsiveImageViewModel from "~/helpers/ViewModels/ResponsiveImageViewModel.js";
import AnchorViewModel from "~/helpers/ViewModels/AnchorViewModel.js";
import { LinkWrap } from "#components";

export default defineNuxtComponent({
	name: "TeaserImageAndText",
	props: {
		component: {
			type: Object,
		},
		dark: {
			type: Boolean,
		},
	},

	data() {
		return {
			content: this.component.content,
			image: this.component.content.image && ResponsiveImageViewModel(this.component.content.image),
			bottomText: this.component.content.bottomText && this.component.content.bottomText.replace("&gt;", "&rsaquo;"),
			reverse: this.component.content.horizontal && !!this.component.content.reverse,
			bedriftLead: this.component.metadata && this.component.metadata.lead,
			bedriftCategory: this.component.metadata && this.component.metadata.category,
		};
	},

	computed: {
		wrapper() {
			if (this.wrap && this.link) return LinkWrap;
			return "div";
		},
		link() {
			const link = this.content.bottomLink && LinkViewModel(this.content.bottomLink);
			if (link) link.text = this.content.bottomLinkText;
			return link;
		},
		anchorLink() {
			return this.content.anchorLink && this.content.anchorLink.anchorText && AnchorViewModel(this.content.anchorLink);
		},
		wrap() {
			return this.link && !this.anchorLink && this.content.chooseLink === "Wrap link around content";
		},
	},
});
</script>

<style lang="scss" scoped>
.teaser {
	color: $color-text;

	&.dark {
		color: #fff;
	}

	:deep(.image-container) {
		display: block;
		width: 100%;
	}

	.teaser-content-container {
		.richtext {
			color: $color-neutrals-700-shade;

			@include font-text-l;

			&.dark {
				color: $color-neutrals-200-tint;
			}
		}
	}

	.wrap {
		&:hover h2 {
			text-decoration: underline;
		}
	}

	.image-container + .teaser-content-container {
		margin-top: $spacing-l;
	}

	:deep(.horizontal) {
		display: flex;
		align-items: flex-start;
		gap: $spacing-4xl;

		.teaser-content-container {
			width: 50%;
		}

		.image-container {
			width: 50%;

			:deep(img) {
				width: auto;
				max-height: 300px;
			}
		}

		&.reverse {
			flex-direction: row-reverse;
		}

		@include breakpoint(mobile) {
			flex-direction: column;

			.teaser-content-container,
			.image-container {
				width: 100%;
			}

			&.reverse {
				flex-direction: column;
			}
		}
	}
}
</style>
